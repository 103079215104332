let appName = process.env.VUE_APP_APPLICATION_NAME;

// todo: we require both the original and the override. we do deliver out the expected result, but we bloat. invert order, and then when importing dont import existing keys
let populateDirectives =  () => {
    let directives = {};

    // core directives
    let context = require.context("@/client/extensions/directives/directives",  true, /\.(js)$/);
    context.keys().forEach((key) => {
        let result = context(key).default;
        let name = key.split('/').pop().replace('.js', '');
        directives[name] = result;
    });

    // directive overrides
    context = require.context('@/', true, /\/overrides\/client\/extensions\/directives\/directives\/.*\.js/);
    context.keys().forEach(key => {
        let result = context(key).default;
        let name = key.split('/').pop().replace('.js', '');
        directives[name] = result;
    });

    //  app directives
    context = require.context('@/client/applications/', true, /^\.\/.*\/extensions\/directives\/directives.*\.js$/);
    context.keys().forEach(key => {
        if (! key.startsWith('./'+appName)) {
            return;
        }

        let result = context(key).default;
        let name = key.split('/').pop().replace('.js', '');
        directives[name] = result;
    });

    //  app override directives
    context = require.context('@/', true, /overrides\/client\/applications\/.*\/extensions\/directives\/directives\/.*\.js$/);
    context.keys().forEach(key => {
        // filter only the modules for out application
        if ( ! key.startsWith('./overrides/client/applications/'+appName)) {
            return;
        }

        let result = context(key).default;
        let name = key.split('/').pop().replace('.js', '');
        directives[name] = result;
    });

    return directives;
};

export default () => {
    let directives =  populateDirectives();

    return {
        install(instance) {
            for (const [name, directive] of Object.entries(directives) ) {
                instance.directive(name, directive);
            }
        }
    }
};
